.atk-custom-card-section .Polaris-Card__Section {
  padding-bottom: 0;
}

.atk-custom-collapse-button .Polaris-ButtonGroup {
  display: flex;
  justify-content: flex-end;
}

.atk-custom-collapse {
  margin-top: 8px;
}

.atk-custom-collapse-tooltip {
  display: flex;
  justify-content: flex-start;
  margin-top: 0.8rem;
}
