$sm-screen-width: 640px;

:root {
  --text-color: #757575;
  --title-color: #333333;
  --black-color: #000000;
  --main-color: #f27321;
  --orange-color: #febd5b;
  --gray-color: #77869e;
  --gray2-color: #a2adb3;
  --border-color: #e6e6e6;
  --ak-text-color: #000000;
  --ak-background: #ffffff;
  --ak-card-shadow: 0px 0px 10px rgba(146, 146, 146, 0.25);
  --ak-divider: #e1e3e5;
  --apname-color: #5a5a5a;
  --rem-base: 16px;
}

div[data-theme="dark"] {
  --ak-text-color: #ffffff;
  --ak-background: #000000;
  --title-color: #ffffff;
  --ak-card-shadow: var(
    --p-card-shadow,
    0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15)
  );
  --ak-divider: #454749;
  --apname-color: #ffffff;
}
