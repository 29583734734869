@import "@shopify/polaris/build/esm/styles.css";
@import "autoketing-new/dist/styles.css";

@import "fonts";
@import "base";
@import "variables";

// polaris
@import "./polaris/button";

@import "customize";

.app-container {
  max-width: 1140px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.app-tricks {
  margin-top: 5px;
}

.app-help-text {
  font-size: 13px;
  word-break: break-word;
}

body {
  font-family:
    ShopifySans,
    Helvetica,
    Arial,
    Lucida Grande,
    sans-serif;
  font-weight: 400;
}

.Autoketing-main {
  padding-top: 86px;
  padding-bottom: 100px;
  margin-left: 0;

  @media (min-width: 48.0625em) {
    margin-left: 280px;
  }

  transition:
    box-shadow 0.3s 0.15s,
    transform 0.3s,
    margin-left 0.3s,
    margin-right 0.3s,
    width 0.3s,
    z-index 0s ease 0.3s;
}

@media screen and (max-width: 640px) {
  .Autoketing-main {
    padding-top: 76px;
    margin-right: 16px;
    margin-left: 16px;
  }
}

#google_translate_element {
  display: none;
}

.atk-center-head {
  .Polaris-Stack__Item {
    display: flex;
    align-items: center;
  }
}

.autoketing_save_bar {
  position: fixed;
  top: 0;
  width: 100%;
  background: var(--p-surface);
  left: 0;
  z-index: 999999;
  box-shadow: 0 2px 5px 0 #00000033;
  padding: 14px;
}

.atk-on-off,
.atk-on-off > div {
  display: flex;
}

@media screen and (max-width: $sm-screen-width) {
  .atk {
    &-row {
      flex-direction: column;
    }
  }
}

#review-app {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    background-color: #bcbec0;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    background-color: #f5f5f5;
  }

  h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: var(--ak-text-color);
    margin-bottom: 8px;
  }

  p {
    font-size: 14px;
    line-height: 20px;
    color: var(--ak-text-color);
    margin-bottom: 16px;
  }

  .ak-star-animate {
    cursor: pointer;
    margin-left: -35px;

    .star {
      margin-right: 8px;
      display: inline-block;

      &.hidden-star {
        opacity: 0;
        margin-right: 0;
      }

      &.animate {
        -webkit-animation: animate-star 0.5s ease-in-out;
        animation: animate-star 0.5s ease-in-out;
      }
    }
  }
}

.Polaris-Modal-Dialog__Modal {
  position: fixed;
  bottom: unset;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 48.0625em) {
  .Polaris-Modal-Dialog__Modal {
    max-width: 62rem !important;
  }
}

.app-limit {
  position: relative;

  &__box-limit {
    backdrop-filter: blur(1px) contrast(0.95);
    position: absolute;
    z-index: 30;
    height: 100%;
    width: 100%;
    display: table;
  }
}

.Polaris-Labelled__HelpText {
  font-size: 13px;
}

.app-title {
  font-size: 20px;
  font-weight: 700;
}

.fb_reset {
  background: none;
  border: 0;
  border-spacing: 0;
  color: #000;
  cursor: auto;
  direction: ltr;
  font-family: "lucida grande", tahoma, verdana, arial, sans-serif;
  font-size: 11px;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1;
  margin: 0;
  overflow: visible;
  padding: 0;
  text-align: left;
  text-decoration: none;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
}
