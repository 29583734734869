.Polaris-Button--primary {
  box-shadow: unset;
  transition: all 0.3s ease;
  color: var(--p-text-on-primary);
  background-color: var(--main-color);

  &:hover {
    background-color: #ff5200;
  }

  .Polaris-Button__Spinner {
    svg {
      fill: #fff;
    }
  }
}

.Polaris-Button--loading,
.Polaris-Button--loading:hover,
.Polaris-Button--loading.Polaris-Button--disabled {
  color: transparent;
}
