// ShopifySans
@font-face {
  font-family: "ShopifySans";
  src: url("/assets/fonts/ShopifySans/ShopifySans--light.woff2") format("woff2");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "ShopifySans";
  src: url("/assets/fonts/ShopifySans/ShopifySans--regular.woff2")
    format("woff2");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "ShopifySans";
  src: url("/assets/fonts/ShopifySans/ShopifySans--medium.woff2")
    format("woff2");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "ShopifySans";
  src: url("/assets/fonts/ShopifySans/ShopifySans--bold.woff2") format("woff2");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "ShopifySans";
  src: url("/assets/fonts/ShopifySans/ShopifySans--extrabold.woff2")
    format("woff2");
  font-style: normal;
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: "ShopifySans";
  src: url("/assets/fonts/ShopifySans/ShopifySans--black.woff2") format("woff2");
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}

// Avenir
@font-face {
  font-family: "Avenir";
  src: url("/assets/fonts/Avenir/AvenirMedium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Avenir";
  src: url("/assets/fonts/Avenir/AvenirRegular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
  font-size: 14;
}
